import Axios from "axios";
let token = window.localStorage.getItem("accessToken") || "";

const axios = Axios.create({

  // baseurl for production server
  // baseURL: `https://api.believerssign.com/api/v1`,

  // baseurl for staging server
  baseURL: `https://api.affiliate-staging.amardokan.com.bd/api/v1`,

  // baseURL:
  //   process.env.NODE_ENV === "development"
  //     ? `https://api.reseller.b2gsoft.xyz/api/v1`
  //     : `https://api.believerssign.com/api/v1`,
  
  // baseurl for dev or local server
  // baseURL: `http://localhost:8074/api/v1`,

  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  },
 
});

export default axios;
